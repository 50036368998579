import React, { useState } from 'react'
import moment from 'moment'
import { addDays } from 'date-fns'
import { Flex } from '@chakra-ui/react'
import { CustomDatePicker, InputComtrol, Select } from '../../../UI'
import {
  FormatMoney,
  ClearFormatMoney,
  Schedule,
  ReasonList
} from '../../../../Utils'

export const transferredAmountName = {
  'USED-AMOUNT-SOLIDARITY-HEALTH-FUND': 'Monto transferido'
}
export const usedAmountName = {
  'PAYMENT-MADE-SOLIDARITY-HEALTH-FUND': 'Valor estimado de ayuda',
  'AID-DEPOSIT-DISASTER-SUPPORT': 'Valor estimado de ayuda',
  'AID-DEPOSIT-FIRE-SUPPORT': 'Valor estimado de ayuda'
}

const RenderInputRequired = ({ formik, program }) => {
  const { hours, minutes, periods } = Schedule
  const TRANSFERREDAMOUNTLABEL =
    transferredAmountName[formik.values?.currentKeyHistory] ||
    'Monto transferido'
  const USEDAMOUNTLABEL =
    usedAmountName[formik.values?.currentKeyHistory] || 'Monto utilizado'

  const handleChangeDateDelivery = (date) => {
    formik.setFieldValue('dateDelivery', date)
  }
  const handleChangeEntryDate = (date) => {
    formik.setFieldValue('entryDate', date)
  }

  const handleChangeStartHour = (value) => {
    formik.setFieldValue('entryHour', value)
  }

  const handleChangeStartMinutes = (value) => {
    formik.setFieldValue('entryMinute', value)
  }

  const handleChangeStartPeriod = (value) => {
    formik.setFieldValue('entryPeriod', value)
  }

  const formatAmount = () => {
    const format = FormatMoney(ClearFormatMoney(formik.values.amount))
    formik.setValues({
      ...formik.values,
      amount: format
    })
  }

  const formatTransferredAmount = (nameField) => {
    if (formik.values[nameField]) {
      const format = FormatMoney(ClearFormatMoney(formik.values[nameField]))
      formik.setValues({
        ...formik.values,
        [nameField]: format
      })
    }
  }

  const handleChangeStartHourForExit = (value) => {
    formik.setValues({
      ...formik.values,
      exitHour: value
    })
  }

  const handleChangeStartMinutesForExit = (value) => {
    formik.setValues({
      ...formik.values,
      exitMinute: value
    })
  }

  const handleChangeStartPeriodForExit = (value) => {
    formik.setValues({
      ...formik.values,
      exitPeriod: value
    })
  }

  const handleChangeNewExitDate = (date) => {
    if (date) {
      const currentExitDate = moment(program.exitDate)
      const newExit = moment(date)
      const diffDays = newExit.diff(currentExitDate, 'days')

      formik.setValues({
        ...formik.values,
        extensionDays: diffDays.toString(),
        newExitDate: date
      })
    }
  }

  const handleChangeReceptionDate = (date) => {
    formik.setFieldValue('receptionDate', date)
  }

  const handleChangeReason = (value) => {
    formik.setFieldValue('reason', value)
  }

  return (
    <Flex direction={'column'} gap={2} my={2}>
      {formik.values.isRequiredDate && (
        <InputComtrol id={'dateDelivery'} label={'Fecha de entrega'}>
          <CustomDatePicker
            placeholder={'Fecha de entrega'}
            currentDate={formik.values.dateDelivery}
            onChange={(date) => handleChangeDateDelivery(date)}
            error={Boolean(formik.errors.dateDelivery)}
            errorText={formik.errors.dateDelivery}
          />
        </InputComtrol>
      )}
      {formik.values.isRequiredAmount && (
        <InputComtrol
          id={'amount'}
          label={'Monto aprobado'}
          type={'currency'}
          onBlur={formatAmount}
          value={formik.values.amount}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.amount)}
          errorText={formik.errors.amount}
        />
      )}
      {formik.values.isRequiredEntryDate && (
        <Flex gap={2}>
          <InputComtrol id={'entryDate'} label={'Fecha de entrada'}>
            <CustomDatePicker
              placeholder={'Fecha de entrega'}
              currentDate={formik.values.entryDate}
              onChange={(date) => handleChangeEntryDate(date)}
              error={Boolean(formik.errors.entryDate)}
              errorText={formik.errors.entryDate}
            />
          </InputComtrol>
          <InputComtrol label={'Hora'}>
            <Flex gap={1} align={'center'}>
              <Select
                list={hours}
                value={formik.values.entryHour}
                bg={'white'}
                onChange={(value) => handleChangeStartHour(value)}
              />
              {':'}
              <Select
                list={minutes}
                value={formik.values.entryMinute}
                bg={'white'}
                onChange={(value) => handleChangeStartMinutes(value)}
              />
              <Select
                list={periods}
                value={formik.values.entryPeriod}
                bg={'white'}
                onChange={(value) => handleChangeStartPeriod(value)}
              />
            </Flex>
          </InputComtrol>
        </Flex>
      )}

      {formik.values.isRequiredTransferredAmount && (
        <>
          {formik.values?.currentKeyHistory ===
          'GIFTCARD-RECEPTION-NEWBORN-BENEFIT' ? (
            [
              program?.newbornList.map((item, index) => (
                <InputComtrol
                  label={`Monto transferido hijo ${index + 1} (${item.name})`}
                  type={'currency'}
                  value={formik.values.newbornTransferedValues[item._id] || ''}
                  onBlur={() => {
                    if (formik.values.newbornTransferedValues[item._id]) {
                      const formatedValue = FormatMoney(
                        ClearFormatMoney(
                          formik.values.newbornTransferedValues[item._id]
                        )
                      )
                      formik.setFieldValue('newbornTransferedValues', {
                        ...formik.values.newbornTransferedValues,
                        [item._id]: formatedValue
                      })
                    }
                  }}
                  onChange={(e) => {
                    const {
                      target: { value }
                    } = e

                    const newValuesPayload = {
                      ...formik.values.newbornTransferedValues,
                      [item._id]: value
                    }

                    formik.setFieldValue(
                      'newbornTransferedValues',
                      newValuesPayload
                    )

                    const totalNewborn = Object.values(newValuesPayload).reduce(
                      (acum, newbornValue) => {
                        acum += ClearFormatMoney(newbornValue)
                        return acum
                      },
                      0
                    )

                    formik.setFieldValue(
                      'transferredAmount',
                      FormatMoney(totalNewborn)
                    )
                  }}
                />
              )),
              <InputComtrol
                readOnly
                id={'transferredAmount'}
                label="Total"
                type={'currency'}
                value={formik.values.transferredAmount}
                error={Boolean(formik.errors.transferredAmount)}
                errorText={formik.errors.transferredAmount}
              />
            ]
          ) : (
            <InputComtrol
              id={'transferredAmount'}
              label={TRANSFERREDAMOUNTLABEL}
              type={'currency'}
              value={formik.values.transferredAmount}
              onChange={formik.handleChange}
              onBlur={() => formatTransferredAmount('transferredAmount')}
              error={Boolean(formik.errors.transferredAmount)}
              errorText={formik.errors.transferredAmount}
            />
          )}
        </>
      )}

      {formik.values.isRequiredUsedAmount && (
        <InputComtrol
          id={'usedAmount'}
          label={USEDAMOUNTLABEL}
          type={'currency'}
          value={formik.values.usedAmount}
          onChange={formik.handleChange}
          onBlur={() => formatTransferredAmount('usedAmount')}
          error={Boolean(formik.errors.usedAmount)}
          errorText={formik.errors.usedAmount}
        />
      )}
      {formik.values.isExtension && (
        <InputComtrol id={'newExitDate'} label={'Fecha de salida'}>
          <CustomDatePicker
            placeholder={'DD/MM/YYYY'}
            currentDate={formik.values.newExitDate}
            onChange={(date) => handleChangeNewExitDate(date)}
            error={Boolean(formik.errors.newExitDate)}
            errorText={formik.errors.newExitDate}
            minDate={addDays(new Date(program.exitDate), 0)}
          />
        </InputComtrol>
      )}
      {formik.values.isExtension && (
        <InputComtrol
          isRequired={false}
          id={'extensionDays'}
          label={'Días de extensión'}
          type={'number'}
          value={formik.values.extensionDays}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.extensionDays)}
          errorText={formik.errors.extensionDays}
          readOnly={true}
        />
      )}
      {formik.values.isExit && (
        <>
          <InputComtrol id={'newExitDate'} label={'Fecha de salida'}>
            <CustomDatePicker
              placeholder={'DD/MM/YYYY'}
              currentDate={formik.values.newExitDate}
              onChange={(date) => handleChangeNewExitDate(date)}
              error={Boolean(formik.errors.newExitDate)}
              errorText={formik.errors.newExitDate}
              minDate={addDays(
                new Date(
                  program?.isRequiredExtension
                    ? program?.extensionDate
                    : program?.exitDate
                ),
                0
              )}
            />
          </InputComtrol>
          {/* --- */}
          <InputComtrol label={'Hora'}>
            <Flex gap={1} align={'center'}>
              <Select
                list={Schedule.hours}
                value={formik.values.exitHour}
                bg={'white'}
                onChange={(value) => handleChangeStartHourForExit(value)}
              />
              {':'}
              <Select
                list={Schedule.minutes}
                value={formik.values.exitMinute}
                bg={'white'}
                onChange={(value) => handleChangeStartMinutesForExit(value)}
              />
              <Select
                list={Schedule.periods}
                value={formik.values.exitPeriod}
                bg={'white'}
                onChange={(value) => handleChangeStartPeriodForExit(value)}
              />
            </Flex>
          </InputComtrol>
          <InputComtrol
            id={'totalDays'}
            label={'Total de días hospedado'}
            type={'number'}
            value={formik.values.totalDays}
            onChange={formik.handleChange}
            error={Boolean(formik.errors.totalDays)}
            errorText={formik.errors.totalDays}
          />
        </>
      )}
      {formik.values.isRequiredReason && (
        <InputComtrol id={'reason'} label={'Renuncia o perdida'}>
          <Select
            bg={'white'}
            list={ReasonList}
            value={formik.values.reason}
            onChange={handleChangeReason}
          />
        </InputComtrol>
      )}
      {formik.values.isRequiredReceptionDate && (
        <InputComtrol
          id={'receptionDate'}
          label={'Fecha de recepción de renuncia'}
        >
          <CustomDatePicker
            placeholder={'DD/MM/AAAA'}
            currentDate={formik.values.receptionDate}
            onChange={(date) => handleChangeReceptionDate(date)}
            error={Boolean(formik.errors.receptionDate)}
            errorText={formik.errors.receptionDate}
          />
        </InputComtrol>
      )}
    </Flex>
  )
}

export default RenderInputRequired
